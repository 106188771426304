import React, { Component } from "react"
import axios from "axios"

import "./form.css"
import buttonIcon from "../images/button-icon.png"

const initialState = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
  nameError: "",
  emailError: "",
  messageError: "",
}

class ContactForm extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      nameError: "",
      emailError: "",
      messageError: "",
      loading: false,
    }
  }

  onChange = e => {
    /*
        Because we named the inputs to match their
        corresponding values in state, it's
        super easy to update the state
      */
    this.setState({ [e.target.name]: e.target.value })
  }

  validate = () => {
    let nameError = ""
    let emailError = ""
    let messageError = ""

    if (!this.state.name) {
      nameError = "Name cannot be blank"
    }

    if (!this.state.message) {
      messageError = "Message cannot be blank"
    }

    if (!this.state.email.includes("@")) {
      emailError = "Invalid Email"
    }

    if (emailError || nameError || messageError) {
      this.setState({ emailError, nameError, messageError })
      return false
    }

    return true
  }

  onSubmit = e => {
    e.preventDefault()
    // get our form data out of state
    const { name, email, phone, company, message } = this.state
    const isValid = this.validate()

    if (isValid) {
      axios
        .post(
          "https://xjgutxkjcd.execute-api.eu-central-1.amazonaws.com/dev/email",
          { name, email, phone, company, message }
        )
        .then(result => {})
      this.setState(initialState)
      this.setState({
        loading: true,
      })
    }
  }

  loadOrShowMsg() {
    if (this.state.loading) {
      return <p className="response">You'r message is sent.</p>
    }
  }

  render() {
    const { name, email, phone, company, message } = this.state
    return (
      <>
        <form className="app-form" onSubmit={this.onSubmit}>
          <div class="app-form-group">
            <input
              className="app-form-control"
              placeholder="Name*"
              type="text"
              name="name"
              value={name}
              onChange={this.onChange}
            />
          </div>
          <div className="error-msg">{this.state.nameError}</div>
          <div class="app-form-group">
            <input
              className="app-form-control"
              placeholder="Email*"
              type="text"
              name="email"
              value={email}
              onChange={this.onChange}
            />
          </div>
          <div className="error-msg">{this.state.emailError}</div>
          <div class="app-form-group">
            <input
              className="app-form-control"
              placeholder="Phone"
              type="text"
              name="phone"
              value={phone}
              onChange={this.onChange}
            />
          </div>
          <div class="app-form-group">
            <input
              className="app-form-control"
              placeholder="Company"
              type="text"
              name="company"
              value={company}
              onChange={this.onChange}
            />
          </div>
          <div class="app-form-group">
            <input
              className="app-form-control"
              placeholder="Message*"
              type="textarea"
              name="message"
              value={message}
              onChange={this.onChange}
            />
          </div>
          <div className="error-msg">{this.state.messageError}</div>

          <button className="formbtn" type="submit">
            Submit{" "}
            <span>
              <img src={buttonIcon} />
            </span>
          </button>
        </form>
        {this.loadOrShowMsg()}
      </>
    )
  }
}

export default ContactForm
